const BASKETBALL_SORTING = {
    "Guard": 1,
    "Forward": 2,
    "Center": 3,
};

const FOOTBALL_SORTING = {
    "Goalkeeper": 1,
    "Center Back": 2,
    "Left Back": 3,
    "Right Back": 4,
    "Defensive Midfielder": 5,
    "Central Midfielder": 6,
    "Attacking Midfielder": 7,
    "Left Winger": 8,
    "Right Winger": 9,
    "Center Forward": 10,
};

const sortRoster = (array) => {
    return array.sort((a, b) => {
        const nameA = `${a.LAST_NAME} ${a.FIRST_NAME}`.toUpperCase();
        const nameB = `${b.LAST_NAME} ${b.FIRST_NAME}`.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

const comparePosition = (position1, position2, sport) => {
    switch (sport) {
        case "Basketball":
            return BASKETBALL_SORTING[position1] - BASKETBALL_SORTING[position2];
        case "Football":
            return FOOTBALL_SORTING[position1] - FOOTBALL_SORTING[position2];
        default:
            return position1 - position2;
    }
}

export { sortRoster, comparePosition };
