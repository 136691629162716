import { useState, useEffect } from "react";
import { fetchSessions } from "src/services/apis";
import { fetchPlSessions } from "src/services/apis";
import {
  playerSessionsToEvents,
  sessionsToEvents,
} from "src/utils/calendarUtils";
import { useRoster } from "./useRoster";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useEvents = (
  colors,
  explanations,
  playerDataShown,
  startDate = null,
  endDate = null,
  selPlayerCal = null
) => {
  const [events, setEvents] = useState([]);
  const { players } = useRoster();
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadPlayersData = async () => {
      try {
        setEvents([]); // Empty the sessions for every time parameters are changed

        const data = await fetchPlSessions(
          selPlayerCal,
          startDate,
          endDate,
          players,
          axiosPrivate
        );
        const evs = playerSessionsToEvents(data, colors, explanations);

        setEvents(evs);
      } catch (error) {
        console.error("Error loading player sessions:", error);
      }
    };

    const loadTeamsData = async () => {
      try {
        setEvents([]); // Empty the sessions for every time parameters are changed

        const data = await fetchSessions(axiosPrivate, startDate, endDate);
        const evs = sessionsToEvents(data, colors, explanations);

        setEvents(evs);
      } catch (error) {
        console.error("Error loading team sessions:", error);
      }
    };

    if (!playerDataShown) {
      loadTeamsData();
    } else if (selPlayerCal) {
      loadPlayersData();
    }

  }, [selPlayerCal, startDate, endDate]);

  return { events };
};

export { useEvents };
