import { useState, useEffect } from "react";
import { fetchRosterStatus } from "src/services/apis";
import { useRoster } from "./useRoster";
import { sortRoster } from "src/utils/sortingUtils";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useRosterStatus = () => {
  const [rosterStatus, setRosterStatus] = useState([]);
  const { players } = useRoster();
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadPlayers = async () => {
      try {
        const data = await fetchRosterStatus(axiosPrivate);

        const mergedData = data.map((team) => {
          const player = players.find((p) => p.PLAYER_ID === team.PLAYER_ID);
          if (player) {
            return {
              ...player,
              ...team,
            };
          }

          return team; // or handle the case where player is not found
        });

        setRosterStatus(sortRoster(mergedData));
      } catch (error) {
        console.error("Error loading player roster status:", error);
      }
    };

    if (players.length) {
      loadPlayers();
    }

  }, [players]);

  return { rosterStatus };
};

export { useRosterStatus };
