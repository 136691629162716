import { useState, useEffect } from "react";
import { fetchMetrics } from "src/services/apis/metricsApi";
import { useRoster } from "./useRoster";
import { useAxiosPrivate } from "./useAxiosPrivate";
import { transformData } from "src/utils/metricsUtils";
import { useGlobalState } from "src/contexts";

const useMetrics = (startDate, endDate) => {
	const [metrics, setMetrics] = useState([]);
	const [teamAvgMetrics, setTeamAvgMetrics] = useState([]);
	const { players } = useRoster();
	const { axiosPrivate } = useAxiosPrivate();
	const { state } = useGlobalState();

	useEffect(() => {
		const loadMetrics = async () => {
			try {
				const data = await fetchMetrics(
					startDate,
					endDate,
					axiosPrivate
				);

				const mergedData = data.map((team) => {
					const player = players.find(
						(p) => p.PLAYER_ID === team.PLAYER_ID
					);
					if (player) {
						return {
							...player,
							...team,
						};
					}

					return team; // or handle the case where player is not found
				});

				const isTeamKab = ["KAB", "FCSB"].includes(state?.teamInfo?.teamCode);
				const sport = state?.teamInfo?.sport;

				const [transformedData, teamDetails] = transformData(
					mergedData,
					isTeamKab,
					sport
				);

				setTeamAvgMetrics(teamDetails);
				setMetrics(transformedData);

			} catch (error) {
				console.error("Error loading metrics:", error);
			}
		};

		if (startDate, endDate, players.length) {
			loadMetrics();
		}
	}, [startDate, endDate, players]);

	return { metrics, teamAvgMetrics };
};

export { useMetrics };
