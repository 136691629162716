const fetchTeamIcon = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get("/teams/avatar/",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );
        return response.data;

    } catch (error) {
        console.error('Error fetching Team Icon:', error);
        throw error;
    }
}

export { fetchTeamIcon };
