import { useEffect, useState } from "react";
import { fetchMuscles, fetchMyoPerMuscle } from "src/services/apis";
import { useAxiosPrivate } from "./useAxiosPrivate";

const DEFAULT_COLOR = "#3f3f3f";
const GREEN_COLOR = "#008000";
const RED_COLOR = "#FF0000";
const ORANGE_COLOR = "#CC8400";

const DEFAULT_MUSCLE_COLORS = {
    quadricepsColorRH: DEFAULT_COLOR,
    quadricepsColorLH: DEFAULT_COLOR,
    rectusColorRH: DEFAULT_COLOR,
    rectusColorLH: DEFAULT_COLOR,
    bicepColorRH: DEFAULT_COLOR,
    bicepColorLH: DEFAULT_COLOR,
    semiColorRH: DEFAULT_COLOR,
    semiColorLH: DEFAULT_COLOR,
    adductorsColorRH: DEFAULT_COLOR,
    adductorsColorLH: DEFAULT_COLOR,
    gastrocnemiusColorRH: DEFAULT_COLOR,
    gastrocnemiusColorLH: DEFAULT_COLOR,
    soleusColorRH: DEFAULT_COLOR,
    soleusColorLH: DEFAULT_COLOR,
};

const MUSCLES_KEYS = {
    1: "quadriceps",
    2: "bicep",
    3: "rectus",
    4: "semi",
    5: "adductors",
    6: "gastrocnemius",
    7: "soleus",
};

const useMuscles = () => {
    const [muscles, setMuscles] = useState([]);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadData = async () => {
            try {
                const dataMuscles = await fetchMuscles(axiosPrivate);
                setMuscles(dataMuscles);
            } catch (error) {
                console.error("Error loading muscles, capacity:", error);
            }
        };

        loadData();
    }, []);

    return { muscles };
};

const useMuscleColors = (capacity) => {
    const [muscleColors, setMuscleColors] = useState(DEFAULT_MUSCLE_COLORS);

    useEffect(() => {
        if (capacity?.length > 0) {
            const averageCapacities = {};

            capacity.forEach((item) => {
                const key = `${item.muscle}_${item.location}`;
                if (!averageCapacities[key]) {
                    averageCapacities[key] = { sum: 0, count: 0 };
                }

                averageCapacities[key].sum += item.capacity;
                averageCapacities[key].count += 1;
            });

            const colorArray = [];

            Object.keys(averageCapacities).forEach((key) => {
                const [muscle, location] = key.split("_");
                const average = averageCapacities[key].sum / averageCapacities[key].count;
                colorArray.push({ muscle: parseInt(muscle), location, average });
            });

            const newColors = {};

            colorArray.forEach((item) => {
                const { muscle, location, average } = item;
                let color;

                if (average > 100) {
                    color = RED_COLOR;
                } else if (average > 90) {
                    color = ORANGE_COLOR;
                } else {
                    color = GREEN_COLOR;
                }

                const setColor = (muscle, location, color) => {
                    const keyName = `${MUSCLES_KEYS[muscle]}Color${location}`;
                    newColors[keyName] = color;
                };

                setColor(muscle, location, color);
            });

            setMuscleColors((prevColors) => ({ ...prevColors, ...newColors }));

        } else {
            setMuscleColors(DEFAULT_MUSCLE_COLORS);
        }

    }, [capacity]);

    return { muscleColors };
};

const useMyoPerMuscle = (selPlayer, startDate, endDate) => {
    const [selplayersData, setSelPlayersData] = useState([]);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadPlayerData = async () => {
            try {

                const data = await fetchMyoPerMuscle(selPlayer, startDate, endDate, axiosPrivate);
                setSelPlayersData(data);
            } catch (error) {
                console.error("Error loading player data:", error);
            }
        };

        if (selPlayer) {
            loadPlayerData();
        }

    }, [selPlayer, startDate, endDate]);

    return { selplayersData };
};

export { useMuscles, useMuscleColors, useMyoPerMuscle };
