const formatDate = (date, substractValue = 0, startOfMonth = false, subtractUnit = "Month") => {
    if (!(date instanceof Date) || isNaN(date)) {
        return
    }

    switch (subtractUnit) {
        case "Month":
            date.setMonth(date.getMonth() - substractValue);
            break;
        case "Day":
            date.setDate(date.getDate() - substractValue);
            break;
        case "Year":
            date.setFullYear(date.getFullYear() - substractValue);
            break;
        default:
            break;
    }

    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    let day = startOfMonth ? "01" : date.getDate().toString().padStart(2, "0");

    // Return the date in YYYY-MM-DD format
    return `${year}-${month}-${day}`;
};


const convertToUTC = (dt, time) => {
    const date = new Date(`${dt} ${time}`)

    const utcFullDate = date.toISOString();

    const utcDate = utcFullDate.slice(0, 10)
    const utcTime = utcFullDate.slice(11, 19)

    return [utcDate, utcTime];
}


const convertDateToTitle = (dt) => {
    const date = new Date(dt);
    return date.toLocaleDateString("en-UK", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
};

// Convert date to format required by FCSB example: from 2021/06/15 to 15/06/2021
const convertDateForFCSB = (date) => {
    const dateArray = date.split("/");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
}

export { formatDate, convertToUTC, convertDateToTitle, convertDateForFCSB };
