import { useState, useEffect } from "react";
import { useAxiosPrivate } from "./useAxiosPrivate";
import { fetchLastSessionUpload } from "src/services/apis";
import { formatDate } from "src/utils/dateUtils";

const useLastSessionDate = () => {
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadData = async () => {
            try {
                const lastSesDateData = await fetchLastSessionUpload(axiosPrivate);
                setStartDate(formatDate(new Date(lastSesDateData)));
                setEndDate(formatDate(new Date(lastSesDateData)));
            } catch (error) {
                console.error("Error loading last session uploaded:", error);
            }

        };

        loadData();
    }, []);

    return { startDate, setStartDate, endDate, setEndDate };
};

export { useLastSessionDate };
