import { getStyle, hexToRgba } from "@coreui/utils";
import { useState, useEffect, useCallback } from "react";
import {
  fetchDDModelData,
  fetchSSModelData,
  fetchSPSSModelData,
} from "src/services/apis";
import { useMyoPerMuscle } from "./useMuscles";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useModelData = (selPlayer, selMuscle, muscles, capDate, capacity) => {
  const [imagesSrc, setImagesSrc] = useState("");
  const [modelData, setModelData] = useState(null);
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadModelData = async () => {
      try {
        setImagesSrc("");
        setModelData(null);

        const muscleIndex = parseInt(selMuscle) - 1;
        const muscleData = muscles[muscleIndex];

        const findCapacity = (muscleIndex, location) => {
          return capacity.find(
            (capItem) =>
              capItem.muscle === muscleIndex + 1 &&
              capItem.location === location
          );
        };

        const lhCapacity = findCapacity(muscleIndex, "LH");
        const rhCapacity = findCapacity(muscleIndex, "RH");

        const imagesSrcDict = {
          RH_SS: rhCapacity
            ? `/static/${rhCapacity.gif_paths.normal}`
            : `/static/${muscleData["RH_SS"]}`,
          LH_SS: lhCapacity
            ? `/static/${lhCapacity.gif_paths.normal}`
            : `/static/${muscleData["LH_SS"]}`,
        };

        let data = {
          RH_SS: await fetchSSModelData(selPlayer, selMuscle, "RH", axiosPrivate),
          LH_SS: await fetchSSModelData(selPlayer, selMuscle, "LH", axiosPrivate),
        };

        setImagesSrc(imagesSrcDict);
        setModelData(data);

        const remainingData = {
          RH_DD: await fetchDDModelData(selPlayer, selMuscle, "RH", capDate, axiosPrivate),
          LH_DD: await fetchDDModelData(selPlayer, selMuscle, "LH", capDate, axiosPrivate),
          RH_PS: await fetchSPSSModelData(selPlayer, selMuscle, "RH", axiosPrivate),
          LH_PS: await fetchSPSSModelData(selPlayer, selMuscle, "LH", axiosPrivate),
        };

        setModelData((prevData) => ({ ...prevData, ...remainingData }));

        const updatedImagesSrcDict = {
          ...imagesSrcDict,
          RH_DD: rhCapacity
            ? `/static/${rhCapacity.gif_paths.deformation}`
            : `/static/${muscleData["RH_DD"]}`,
          LH_DD: lhCapacity
            ? `/static/${lhCapacity.gif_paths.deformation}`
            : `/static/${muscleData["LH_DD"]}`,
          RH_PS: rhCapacity
            ? `/static/${rhCapacity.gif_paths.plane}`
            : `/static/${muscleData["RH_PS"]}`,
          LH_PS: lhCapacity
            ? `/static/${lhCapacity.gif_paths.plane}`
            : `/static/${muscleData["LH_PS"]}`,
        };

        setImagesSrc(updatedImagesSrcDict);
      } catch (error) {
        console.error("Error loading model data:", error);
      }
    };

    if (selPlayer && selMuscle && capDate && muscles && capacity) {
      loadModelData();
    }
  }, [selPlayer, selMuscle, muscles, capDate, capacity]);

  return { imagesSrc, modelData };
};

const useMyoChart = (selPlayer, selMuscle, startDate, endDate) => {
  const [chartData, setChartData] = useState(null);
  const { selplayersData } = useMyoPerMuscle(selPlayer, startDate, endDate);

  const prepDataSet = useCallback(() => {
    let set = {
      labels: [],
      datasets: [
        {
          backgroundColor: hexToRgba(getStyle("--cui-red"), 10),
          borderColor: getStyle("--cui-red"),
          pointHoverBackgroundColor: getStyle("--cui-red"),
          borderWidth: 2,
          lineTension: 0,
          label: "Left",
          data: [],
          fill: true,
        },
        {
          backgroundColor: hexToRgba(getStyle("--cui-blue"), 10),
          borderColor: getStyle("--cui-blue"),
          pointHoverBackgroundColor: getStyle("--cui-blue"),
          borderWidth: 2,
          lineTension: 0,
          label: "Right",
          data: [],
          fill: true,
        },
      ],
    };

    if (selplayersData[0] && selplayersData[0].myoskeletals) {
      const filteredAndSorted = selplayersData[0].myoskeletals
        .filter((item) => item.MUSCLE_ID == parseInt(selMuscle) - 1)
        .sort((a, b) => a.REF_DATE.localeCompare(b.REF_DATE));
      filteredAndSorted.forEach((item) => {
        let date = item.REF_DATE.substring(0, 10);
        if (!set.labels.includes(date)) {
          set.labels.push(date);
        }
        let index = item.LOC === "LH" ? 0 : 1;
        set.datasets[index].data.push(item.STRESS);
      });
    }
    return set;
  }, [selplayersData, selMuscle]);

  useEffect(() => {
    setChartData(prepDataSet());
  }, [selplayersData, selMuscle]);

  return { chartData };
};

export { useModelData, useMyoChart };
