import { useState, useEffect } from "react";
import { fetchCapacity, fetchAveragePlCapacity } from "src/services/apis";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useCapacity = (selectedItem, date = null) => {
    const [capacity, setCapacity] = useState();
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadData = async () => {
            try {
                const dataCapacity = await fetchCapacity(selectedItem, date, axiosPrivate);
                setCapacity(dataCapacity);
            } catch (error) {
                console.error("Error loading muscles, capacity:", error);
            }

        };

        if (selectedItem?.length) {
            loadData();
        }
    }, [selectedItem, date]);

    return { capacity };
};

const usePlayerCapacity = (playerId, startDate, endDate) => {
    const [capacity, setCapacity] = useState([]);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {

        const loadPlayerCapacity = async () => {
            try {
                const data = await fetchAveragePlCapacity(playerId, startDate, endDate, axiosPrivate);
                setCapacity(data);
            } catch (error) {
                console.error("Error loading player capacity:", error);
            }
        };

        if (playerId) {
            loadPlayerCapacity();
        }

    }, [playerId, startDate, endDate]);

    return { capacity };
};

export { usePlayerCapacity, useCapacity };
