const fetchLastSessionUpload = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get("/teams/lastsessionupload/", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });

        return response.data?.lastsessiondate;
    } catch (error) {
        console.error("Error fetching last session uploaded:", error);
        throw error;
    }
};

export { fetchLastSessionUpload };
